<template>
  <b-card no-body class="border mt-1">
    <b-card-header class="p-1">
      <b-card-title class="font-medium-2">
        <feather-icon icon="LockIcon" size="18" />
        <span class="align-middle ml-50">{{ $t("field.permission") }}</span>
      </b-card-title>
    </b-card-header>
    <n-table
      :key="`permission-table-${key}`"
      :fields="fields"
      :items="items"
      :busy="loading"
      class="permission-table"
    >
      <template #head(manage) v-if="items.length && !readonly">
        <b-form-checkbox
          class="vs-checkbox-con"
          :checked="getCheckedAllAction()"
          :indeterminate="getCheckedIndeterminateAllAction()"
          @change="checkAllAbility($event)"
          :disabled="readonly"
        ></b-form-checkbox>
      </template>
      <template #cell(code)="data">
        {{ $t(`permission.${data.item.code}`) }}
      </template>
      <template #cell(manage)="data" v-if="!readonly">
        <b-form-checkbox
          class="vs-checkbox-con"
          :checked="getCheckedRowAction(data.item.actions)"
          :indeterminate="
            getCheckedIndeterminateRowAction(
              data.item.avialbleActions,
              data.item.actions
            )
          "
          @change="checkRowAbility(data.item, $event)"
          :disabled="readonly"
        >
          <span class="vs-checkbox">
            <span class="vs-checkbox--check">
              <i class="vs-icon feather icon-check" />
            </span>
          </span>
        </b-form-checkbox>
      </template>

      <template v-for="slot of extendHeaders" v-slot:[slot]="data">
        <b-form-checkbox
          v-bind:key="slot"
          class="vs-checkbox-con"
          v-if="permissionsExist(data.item.avialbleActions, data.field.key)"
          :checked="getCheckedAction(data.item.actions, data.field.key)"
          @change="checkAbility(data.item, data.field.key, $event)"
          :disabled="readonly"
        >
          <span class="vs-checkbox">
            <span class="vs-checkbox--check">
              <i class="vs-icon feather icon-check" />
            </span>
          </span>
        </b-form-checkbox>
      </template>
    </n-table>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import NTable from "@/components/NTable";
import permissionTableField from "./permissionTableField";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    NTable,
  },
  directives: {
    Ripple,
  },
  props: {
    value: {},
    readonly: {},
    loading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  watch: {
    items: function (value) {
      this.extendPermissionTableHeader();
      this.$emit("input", value);
    },
  },
  data() {
    return {
      key: 1,
      extendHeaders: [],
    };
  },
  methods: {
    extendPermissionTableHeader() {
      let length = 0;
      let index = -1;
      this.items.forEach((element, i) => {
        if (element.avialbleActions.length > length) {
          length = element.avialbleActions.length;
          index = i;
        }
      });
      let actions = [];
      if (index != -1) {
        actions = this.items[index].avialbleActions;
      }
      
      for (let i = 0; i < actions.length; i++) {
        if (this.readonly) {
          const index = this.fields.findIndex((field) => {
            return field.key === "manage";
          });
          if (index !== -1) {
            this.fields.splice(index, 1);
          }
        }

        this.fields = [
          ...this.fields,
          {
            key: actions[i],
            label: `button.${actions[i]}`,
          },
        ];
      }

      this.extendHeaders = [
        ...actions.map((action) => {
          return `cell(${action})`;
        }),
      ];
      this.key++;
    },
    permissionsExist(avialbleActions, action) {
      return avialbleActions.indexOf(action) !== -1;
    },
    getCheckedRowAction(actions) {
      return actions.length > 0;
    },
    getCheckedIndeterminateRowAction(avialbleActions, actions) {
      return actions.length > 0 && avialbleActions.length !== actions.length;
    },
    getCheckedAction(actions, action) {
      return actions.indexOf(action) !== -1;
    },
    getCheckedAllAction() {
      return this.items.every((item) => {
        return item.actions.length > 0;
      });
    },
    getCheckedIndeterminateAllAction() {
      const anyChecked = this.items.some((item) => {
        return item.actions.length < item.avialbleActions.length;
      });

      const noChecked = this.items.every((item) => {
        return item.actions.length === 0;
      });

      return noChecked ? false : anyChecked;
    },
    checkAllAbility(checked) {
      if (checked) {
        this.items.map((item) => {
          item.actions = [...item.avialbleActions];
        });
      } else {
        this.items.map((item) => {
          item.actions = [];
        });
      }
    },
    checkRowAbility(item, checked) {
      if (checked) {
        item.actions = [...item.avialbleActions];
      } else {
        item.actions = [];
      }
    },
    checkAbility(item, action, checked) {
      if (checked !== true && checked !== false) {
        return;
      }

      if (checked) {
        const actions = new Set([...item.actions, action]);
        item.actions = [...actions];
      } else {
        const index = item.actions.findIndex((element) => {
          return element === action;
        });
        item.actions.splice(index, 1);
      }
    },
  },
  setup() {
    let fields = [...permissionTableField];
    return { fields };
  },
};
</script>