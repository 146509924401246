export default [
  {
    key: 'increasement',
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'code',
    label: 'field.moduleName',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'manage',
    label: '',
  },
];
