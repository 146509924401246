export default [
  {
    key: 'name',
    label: 'field.roleName',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'description',
    label: 'field.description',
    rules: 'max:200',
    type: 'textarea',
  },
  {
    key: 'abilities',
    label: 'field.permission',
    rules: 'required',
    type: 'permissions',
    cols: 12,
  },
]
